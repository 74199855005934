// gatsby-browser.js
import './src/styles/webfonts.css';
import './src/styles/global.css';
import './src/styles/manual.css';
import './src/styles/plyr.css';

const onRouteUpdate = () => {
  // Scroll to the top of the page on route update
  window.scrollTo(0, 0);
};

export { onRouteUpdate };

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nomination-invite-js": () => import("./../../../src/pages/nomination/invite.js" /* webpackChunkName: "component---src-pages-nomination-invite-js" */),
  "component---src-pages-nomination-invite-x-js": () => import("./../../../src/pages/nomination/invite-x.js" /* webpackChunkName: "component---src-pages-nomination-invite-x-js" */),
  "component---src-pages-nominee-upload-js": () => import("./../../../src/pages/nominee/upload.js" /* webpackChunkName: "component---src-pages-nominee-upload-js" */),
  "component---src-pages-prototypes-js": () => import("./../../../src/pages/prototypes.js" /* webpackChunkName: "component---src-pages-prototypes-js" */),
  "component---src-pages-videos-list-js": () => import("./../../../src/pages/videos/list.js" /* webpackChunkName: "component---src-pages-videos-list-js" */),
  "component---src-templates-landing-pages-js": () => import("./../../../src/templates/landingPages.js" /* webpackChunkName: "component---src-templates-landing-pages-js" */),
  "component---src-templates-sub-pages-js": () => import("./../../../src/templates/subPages.js" /* webpackChunkName: "component---src-templates-sub-pages-js" */),
  "component---src-templates-video-template-js": () => import("./../../../src/templates/videoTemplate.js" /* webpackChunkName: "component---src-templates-video-template-js" */)
}


module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Movers of Tomorrow Award","short_name":"Movers of Tomorrow Award","description":"Morgen fängt heute an! Der Movers of Tomorrow Award, der Engagementpreis der Allianz Foundation, feiert junge Menschen, die sich für eine bessere Zukunft einsetzen.","lang":"de","display":"standalone","icon":"./src/assets/icon.png","start_url":"/","background_color":"#000","theme_color":"#fff","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1d58765e84c891dbcba2305027c9480f"},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"2","matomoUrl":"https://allianz-foundation.de/matomo/","siteUrl":"https://movers-of-tomorrow-award.de/","disableCookies":true,"dev":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/assets/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1d58765e84c891dbcba2305027c9480f"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
